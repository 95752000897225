import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { css } from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 468px;
  width: 100%;
  ${isMobile &&
    css`
      margin-top: 6px;
      border-radius: 20px;
  `}
  ${!isMobile &&
    css`
    margin-top: 20px;
  `}
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper id="BodyWrapper">{children}</BodyWrapper>
}
