import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import 'antd/dist/antd.css';
// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
// import Polling from '../components/Header/Polling'
// import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
// import Reward from './Reward'
import Footer from '../components/Footer'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
// import Earn from './Earn'
// import Manage from './Earn/Manage'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import NavBottom from '../components/NavBottom'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'
import LocalLoader from '../components/LocalLoader'
// import Invite from './Referral'
// import Referral from './Referral/Invite'
import bg1 from '../assets/images/bg1.png'
import bg2 from '../assets/images/bg2.png'
import bg3 from '../assets/images/bg3.png'


const AppWrapper = styled.div<{backgroundColor?: string}>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  position: relative;
  height: auto;
  min-height: 100%;
  background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
  background-image: url(${bg1}), url(${bg2}), url(${bg3});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: auto, auto, auto;
  background-position: left 10% top 20%, center center, right 10% top 60%;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div<{top: number, paddingBottom: number}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme, top }) => theme.mediaWidth.upToSmall`
    padding: 0 16px;
    top: ${top}px
  `};
  position: relative;
  top: ${({top}) => top && `${top}px`};
  padding-bottom: ${({paddingBottom}) => `${paddingBottom}px`};
  // background-color: #000;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}


let timer:any = null
export default function App() {
  let match = useRouteMatch('/referral') || useRouteMatch('/invite')
  const [showStatus, setShowStatus] = useState(false)

  useEffect(() => {
    timer = setTimeout(() => {
      setShowStatus(true)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])
  const topVal = match ? (isMobile ? -240 : -280) : (isMobile ? -184 : -140)
  const paddingBottom = match ? 0 : isMobile ? 0 : 180

  // 路由设置
  const _routeSet = () => {
    return (
      <AppWrapper backgroundColor={match ? '#fff' : '#000'}>
        {/* <URLWarning /> */}
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        {/* 首页弹窗 */}
        <BodyWrapper className='BodyWrapper' top={topVal} paddingBottom={paddingBottom}>
          <Popups />
          {/* <Polling /> */}
          <TopLevelModals />
          <Web3ReactManager>
            <Switch>
              {/* title */}
              <Route exact strict path="/swap" component={Swap} />
              {/* <Route exact strict path="/reward" component={Reward} /> */}
              {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/pool" component={Pool} />
              {/* <Route exact strict path="/uni" component={Earn} /> */}
              {/* <Route exact strict path="/vote" component={Vote} /> */}
              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact path="/create" component={AddLiquidity} />
              <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              {/* <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
              {/* <Route exact strict path="/vote/:id" component={VotePage} /> */}
              {/* <Route exact path="/referral" component={Referral} /> */}
              {/* <Route exact path="/Invite" component={Invite} /> */}
              <Route component={RedirectPathToSwapOnly} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
        { isMobile && <NavBottom />}
        {!isMobile && <Footer />}
      </AppWrapper>
    )
  } 

  return (
    <Suspense fallback={null}>
      {/* <Route component={GoogleAnalyticsReporter} /> */}
      <Route component={DarkModeQueryParamReader} />
      {showStatus ?  _routeSet() : <LocalLoader fill="true" />}
    </Suspense>
  )
}
