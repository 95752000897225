import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { InjectedConnectorOKX } from "./OKX/injected-connectot";
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { WalletConnect } from "./wc2";

import { FortmaticConnector } from "./Fortmatic";
import { NetworkConnector } from "./NetworkConnector";

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL;
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY;
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;
export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "1"
);

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`
  );
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: [534351, 534352],
});
export const injectedOKX = new InjectedConnectorOKX({
  supportedChainIds: [534351, 534352],
});
export const injectedGATE = new InjectedConnectorOKX({
  supportedChainIds: [534351, 534352],
});
// mainnet only
// export const walletconnect = new WalletConnectConnector({
//   rpc: { 534352: NETWORK_URL },
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
//   pollingInterval: 15000
// })
// const [store, actions] = createWeb3ReactStoreAndActions()
export const walletconnect = new WalletConnect({
  defaultChainId: NETWORK_CHAIN_ID,
  options: {
    projectId: process.env.REACT_APP_WC_PRJID ?? "",
    relayUrl: "wss://relay.walletconnect.org",
    chains: [NETWORK_CHAIN_ID],
    optionalChains: [NETWORK_CHAIN_ID],
    rpcMap: { [NETWORK_CHAIN_ID]: NETWORK_URL },
    // optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign', 'eth_call', 'eth_blockNumber'],
    showQrModal: true,
    qrModalOptions: {
      themeVariables: {
        "--w3m-z-index": "1003",
      },
    },
  },
});

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? "",
  chainId: 1,
});

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? "",
  networks: [1],
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: "Uniswap",
  appLogoUrl:
    "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg",
});
