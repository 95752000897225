import { ChainId } from "@mdex/zkscroll-sdk";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
// import { ArrowLeft } from 'react-feather'
import i18next from "i18next";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";
import Logo from "../../assets/images/newLogo.png"; // 导航logo
import { useActiveWeb3React } from "../../hooks";
import { ExternalLink } from "../../theme";
// import { YellowCard } from '../Card'
// import Settings from '../Settings'
// import Menu from '../Menu'
// import { StyledDropDown } from 'components/CurrencyInputPanel'
import Row, { RowFixed } from "../Row";
import Web3Status from "../Web3Status";

// import H5Header from '../../assets/images/Heco-banner-header.png'
import DropDown from "../../assets/images/DropDown-icon.svg";
import RhinoIcon from "../../assets/images/rhino-icon.svg";
import ScroollIcon from "../../assets/images/scrooll-icon.svg";
import LanguageImg from "../../assets/svg/language.svg";
import OrbiterIcon from "../../assets/svg/orbiter.svg";
import scrollImg from "../../assets/svg/scroll.svg";
import shareImg from "../../assets/svg/share.svg";
import inviteLogo from "../../pages/Referral/img/invite_logo.png";
import invitedLogo from "../../pages/Referral/img/invited_logo.png";
import { _collectLink } from "../../utils/urlMethod";
import H5Side from "../H5Side/H5Side";
import "./index.css";
const HeaderBox = styled.div`
  width: 100%;
  top: 0;
  position: relative;

  ${isMobile &&
    css`
      height: 268px;
    `}
  ${!isMobile &&
    css`
      height: 310px;
    `}
`;

// const BannerBg = styled.img`
//   position: absolute;
//   width: 100%;
//   height: 100%;
// `
const BannerLogo = styled.img`
  position: absolute;
  bottom: 0;
  right: 20%;
  width: "576px";
  height: "295px";
`;

const HeaderFrame = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 1001;
  ${!isMobile && css``}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    width: calc(100%);
    position: relative;
    ${isMobile &&
      css`
        padding: 8px 16px;
        display: flex;
        position: fixed;
        z-index: 2;
        background: #000;
      `}
    ${!isMobile &&
      css`
        padding: 0 1rem;
        background-color: rgba(0, 0, 0, 0);
      `}
  `};
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`;

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`;

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: ${({ theme, active }) =>
    !active ? theme.bg1 : theme.bg3};
  border-radius: 24px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: auto;
    margin-left: 10px;
  `};
`;

// const UNIAmount = styled(AccountElement)`
//   color: white;
//   padding: 4px 8px;
//   height: 36px;
//   font-weight: 500;
//   background-color: ${({ theme }) => theme.bg3};
//   background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
// `

// const UNIWrapper = styled.span`
//   width: fit-content;
//   position: relative;
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }

//   :active {
//     opacity: 0.9;
//   }
// `

const HideSmall = styled.span`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const NetworkCard = styled.div`
  width: 150px;
  border-radius: 24px;
  padding: 8px 12px;
  color: #fff;
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`;

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`;

const activeClassName = "ACTIVE";

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  width: fit-content;
  margin: 0 32px;
  font-weight: 500;

  &.${activeClassName} {
    font-weight: 600;
    color: #13f287;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 48px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 30px;
      background: #13f287;
      border-radius: 2px;
    }
  }
  ${isMobile &&
    css`
      font-size: 14px;
      margin: 0 4px;
    `}
  :hover, :focus {
    color: #13f287;
  }
`;

const LogoImg = styled.img`
  height: 28px;
  ${isMobile &&
    css`
      height: 20px;
    `}
`;
const StyledExternalLink = styled(ExternalLink).attrs({ activeClassName })<{
  isActive?: boolean;
}>`
  position: relative;
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  width: fit-content;
  margin: 0 32px;
  font-weight: 500;
  ${isMobile &&
      css`
        font-size: 12px;
        margin: 0 3px;
      `}
    :hover,
  :focus {
    color: #13f287;
  }
  ::after {
    display: inline-block;
    content: "";
    width: 14px;
    height: 14px;
    background: ${`url(${shareImg})`};
    background-size: 100% 100%;
    position: relative;
    left: 5px;
    top: 5px;
  }
`;

const LangList = styled.ul`
  position: absolute;
  right: 1rem;
  top: 75px;
  height: 20px;
  background: #202020;
  box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 10px 0;
  height: auto;
  z-index: 1001;
`;

const LangListLi = styled.li`
  display: flex;
  width: 120px;
  align-items: center;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 15px;
  user-select: none;
  &:hover {
    cursor: pointer;
    color: #13f287;
  }
  &.active {
    color: #13f287;
  }
`;
const StyledHoverList = styled.div`
  position: relative;
  align-items: left;
  outline: none;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  width: fit-content;
  margin: 0 32px;
  font-weight: 500;
  &:hover {
    color: #13f287;
    .link-box {
      display: block;
    }
    ::after {
      transform: rotate(0deg);
    }
  }
  &::after {
    display: inline-block;
    content: "";
    width: 14px;
    height: 14px;
    background: ${`url(${DropDown})`};
    background-size: 100% 100%;
    position: relative;
    left: 5px;
    top: 2px;
    transform: rotate(180deg);
  }
  > .link-box {
    display: none;
    position: absolute;
    top: 20px;
    left: -70%;
    width: max-content;
    .link-list {
      margin-top: 14px;
      padding: 6px 20px 20px 20px;
      border-radius: 10px;
      background: #202020;
    }
  }
`;
const StyledHoverListItem = styled(ExternalLink)`
  display: flex;
  color: #fff;
  margin-top: 16px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  &:hover {
    color: #13f287;
  }
`;
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.TESTNET]: "Scroll Testnet",
  [ChainId.MAINNET]: "Scroll Mainnet",
};

export default function Header() {
  const { account, chainId } = useActiveWeb3React();
  const { t } = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng") || "en";
  const [language, setLanguage] = useState(defaultLang);
  const [isLang, setLangState] = useState(true);
  // 控制侧边了显隐
  const [slideShow, setSildeShow] = useState(false);
  // 链接列表
  const linkList = _collectLink(defaultLang, "");
  // 切换语言列表
  // const [showLang, setShowLang] = useState(false)

  const history = useHistory();
  let matchInvite = useRouteMatch("/invite");
  // let matchInvited = useRouteMatch('/referral')
  let match = useRouteMatch("/invite") || useRouteMatch("/referral");

  // const getBannerImg = () => {
  //   let bannerImg
  //   if (match) {
  //     if (isMobile) {
  //       bannerImg = matchInvite ? H5Banner_invite : (matchInvited ? H5Banner_invited : '')
  //     } else {
  //       bannerImg = matchInvite ? banner_invite : (matchInvited ? banner_invited : '')
  //     }
  //   } else {
  //     if (isMobile) {
  //       bannerImg = H5Banner
  //     } else {
  //       bannerImg = PcBanner
  //     }
  //   }
  //   return bannerImg
  // }
  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()

  // const toggleClaimModal = useToggleSelfClaimModal()

  // const availableClaim: boolean = useUserHasAvailableClaim(account)

  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  // const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  // const showClaimPopup = useShowClaimPopup()

  // const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  // const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  const handleLangChange = (lang: string) => {
    console.log("isLang", language);

    setLangState(!isLang);
    if (lang != language) {
      setLanguage(lang);
      i18next.changeLanguage(lang);
      if (!match) {
        history.push({ pathname: `/swap?lang=${lang}` });
      }
    }
  };

  // 控制侧边栏显隐
  const slideHandle = (val: boolean) => {
    setSildeShow(val);
  };
  // 路由跳转
  // const handleLink = (route: 'Notice' | 'Chart' | 'Domain') => {
  //   window.open(linkList[route])
  // }
  // 语言显示
  // const handleChange = () => {
  //   setShowLang(!showLang)
  // }

  return (
    <HeaderBox id="HeaderBox">
      {/* <BannerBg src={getBannerImg()} alt="banner"></BannerBg> */}
      {!account && !isMobile && match && (
        <BannerLogo
          src={matchInvite ? inviteLogo : invitedLogo}
          alt="logo"
        ></BannerLogo>
      )}
      <HeaderFrame id="HeaderFrame">
        {/* <ClaimModal /> */}
        {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
          <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
        </Modal> */}
        <HeaderRow>
          <UniIcon>
            <Title href={linkList["Home"]}>
              <LogoImg src={Logo} alt="logo" />
            </Title>
          </UniIcon>
          {!isMobile && (
            <HeaderLinks>
              {/* <StyledExternalLink id={`stake-nav-link`} href={linkList['Home']}>
              {t('Home')}
            </StyledExternalLink> */}
              <StyledNavLink
                id={`swap-nav-link`}
                to={`/swap?lang=${defaultLang}`}
              >
                {t("swap")}
              </StyledNavLink>
              <StyledNavLink
                id={`pool-nav-link`}
                to={`/pool?lang=${defaultLang}`}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith("/add") ||
                  pathname.startsWith("/remove") ||
                  pathname.startsWith("/create") ||
                  pathname.startsWith("/find")
                }
              >
                {t("pool")}
              </StyledNavLink>
              {/* <StyledExternalLink id={`stake-nav-link`} href={linkList['Farm']}>
                {t('liquidity')}
              </StyledExternalLink>
              <StyledExternalLink id={`swap-nav-link`} href={linkList['Trade']}>
                {t('reward')}
              </StyledExternalLink>
              <StyledExternalLink id={`boardroom-nav-link`} href={linkList['Boardroom']}>
                {t('boardroom')}
              </StyledExternalLink> */}
              <StyledExternalLink
                id={`info-nav-link`}
                href={linkList["Info"]}
                target="_blank"
              >
                {t("info")}
              </StyledExternalLink>
              {/* <StyledExternalLink id={`crossChain-nav-link`} href={linkList['Bridge']} target='_blank'>
                {t('crossChain')}
              </StyledExternalLink> */}
              <StyledHoverList id="Bridge">
                {t("crossChain")}
                <div className="link-box">
                  <div className="link-list">
                    <StyledHoverListItem
                      href="https://scroll.io/bridge"
                      target="_blank"
                    >
                      <img src={ScroollIcon} alt="" />
                      Scroll Bridge
                    </StyledHoverListItem>
                    <StyledHoverListItem
                      href="https://www.orbiter.finance/?source=Ethereum&dest=Scroll&token=ETH"
                      target="_blank"
                    >
                      <img src={OrbiterIcon} alt="" />
                      Orbiter Bridge
                    </StyledHoverListItem>

                    <StyledHoverListItem
                      href="https://app.rhino.fi/bridge"
                      target="_blank"
                    >
                      <img src={RhinoIcon} alt="" />
                      Rhino Bridge
                    </StyledHoverListItem>
                  </div>
                </div>
              </StyledHoverList>
              <StyledExternalLink
                id={`docs-nav-link`}
                href={linkList["Docs"]}
                target="_blank"
              >
                {t("Docs")}
              </StyledExternalLink>
              <StyledExternalLink
                id={`Geckoterminal-nav-link`}
                href={linkList["Geckoterminal"]}
                target="_blank"
              >
                Geckoterminal
              </StyledExternalLink>
            </HeaderLinks>
          )}
        </HeaderRow>
        {!isMobile && (
          <HeaderControls>
            <HeaderElement>
              <HideSmall>
                <img src={scrollImg} alt="" />
                {chainId && NETWORK_LABELS[chainId] && (
                  <NetworkCard title={NETWORK_LABELS[chainId]}>
                    {NETWORK_LABELS[chainId]}
                  </NetworkCard>
                )}
              </HideSmall>
              {/* {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming UNI</Dots> : 'Claim UNI'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}
              {/* {!availableClaim && aggregateBalance && (
            <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <HideSmall>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </HideSmall>
                )}
                UNI
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}
              <AccountElement
                active={!!account}
                style={{ pointerEvents: "auto" }}
              >
                {/* {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} HT
                </BalanceText>
              ) : null} */}
                <Web3Status />
              </AccountElement>
            </HeaderElement>
            <HeaderElementWrap>
              {/* <Settings /> */}
              {/* <Menu /> */}
            </HeaderElementWrap>
            {/* <MoreWrapper onClick={() => setLangState(!isLang)}></MoreWrapper> */}
            <LangListLi onClick={() => setLangState(!isLang)}>
              <img
                src={LanguageImg}
                style={{ marginRight: "7px" }}
                alt="language"
              />
              <span>{t("langSet")}</span>
            </LangListLi>
          </HeaderControls>
        )}
        {!isLang && !isMobile && (
          <LangList>
            {
              <>
                {/* <ArrowLeft size='20px' onClick={() => handleChange()} style={{marginLeft: '12px', cursor: 'pointer'}}/> */}
                <LangListLi
                  className={language === "zh-CN" ? "active" : ""}
                  onClick={() => handleLangChange("zh-CN")}
                >
                  简体中文
                </LangListLi>
                <LangListLi
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLangChange("en")}
                >
                  English
                </LangListLi>
              </>
            }
            {/* {!showLang && <>
              <LangListLi onClick={() => handleLink('Notice')}>
                <img src={NewsImg} style={{marginRight: '7px'}} alt='news' />
                <span>{t('announcement')}</span>
              </LangListLi>
              <LangListLi onClick={() => handleLink('Chart')}>
                <img src={InfoImg} style={{marginRight: '7px'}} alt='info' />
                <span>Info</span>
              </LangListLi>
              <LangListLi onClick={() => handleChange()}>
                <img src={LanguageImg} style={{marginRight: '7px'}} alt='language' />
                <span>{t('langSet')}</span>
              </LangListLi>
              <LangListLi onClick={() => handleLink('Domain')}>
                <img src={DomainNameImg} style={{marginRight: '7px'}} alt='domain-name' />
                <span>{t('Domain')}</span>
              </LangListLi>
            </>} */}
          </LangList>
        )}
        {isMobile && (
          <React.Fragment>
            <AccountElement
              active={!!account}
              style={{ pointerEvents: "auto" }}
            >
              <Web3Status />
            </AccountElement>
            {/* <HeaderElementWrap>
              <Settings />
            </HeaderElementWrap> */}
          </React.Fragment>
        )}
      </HeaderFrame>
      {/* {!match && <>
        <TitleSub className='TitleSub'>{t('note')}</TitleSub>
        {!isMobile && <TitleSubH5>{t('note1')}</TitleSubH5>}
      </>} */}

      {isMobile && <H5Side callback={slideHandle} status={slideShow} />}
    </HeaderBox>
  );
}
