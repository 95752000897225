import {
  PopWrap,
  Pop,
  PopItem,
  ItemImg,
  ItemA,
  Blank,
  PopContent,
  ImgIcon,
  LeftContent,
  FlexContent,
  Content
} from './H5SideCss'
import { _collectLink } from '../../utils/urlMethod'
import vulnerability from './img/vulnerability.png'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import fluidity from './img/fluidity.png'
import notice from './img/notice.png'
// import apply from './img/apply.png'
import fund from './img/fund.png'
import faq from './img/faq.png'
// import chart from '../../assets/images/marker.png'
import arrow from './img/arrow.png'
// import destory from './img/destory.png'
// import crossBridge from './img/crossBridge.png'
import dao from './img/dao.png'
// import inviteCommission from './img/inviteCommission.png'
// import imo from './img/imo.png'
// import imo from './img/imo.png'
import domain from '../../assets/svg/domainName.svg'

const H5Side = ( props:any )=> {
  // 彈出狀態
  const [status, setStatus] = useState(false)

  // 国际化
  const { t } = useTranslation()

  // 语言
  const defaultLang = localStorage.getItem('i18nextLng') || 'en'

  // 链接列表
  const linkList = _collectLink(defaultLang, '')

  // 关闭弹窗
  const closeHandle = () => {
    setStatus(!status)
    props.callback(!status)
  }

  useEffect(() => {
    // 设置弹出状态
    setStatus(props.status)
  },[props.status])

  // 渲染列表
  const sideRander = () => {
    let list:Array<any>  = []
    const sideList = [
      {name: 'FAQ', url: linkList['Faq'],pic: faq},
      // {name: t('charts'), url: linkList['Chart'],pic: chart},
      // {name: 'IMO', url: linkList['IMO'],pic: imo},
      // {name: t('Apply'), url: linkList['Apply'],pic: apply},
      {name: t('Fluidity'), url: linkList['Fluidity'],pic: fluidity},
      {name: t('ZebraApplication'), url: linkList['Fund'],pic: fund},
      {name: t('Vulnerability'), url: linkList['Vulnerability'],pic: vulnerability},
      {name: t('Notice'), url: linkList['Notice'],pic: notice},
    ]
    sideList.forEach((kind, index) => {
      list.push(
        <PopItem key={`sideRander${index}`} onClick={() => window.location.href = kind.url}>
          <LeftContent>
            <ItemImg src={kind.pic} alt=""/>
            <ItemA>{kind.name}</ItemA>
          </LeftContent>
          <ImgIcon size='16px' src={arrow} alt="arrow"></ImgIcon>
        </PopItem>
      )
    })
    return list
  }

  const HeadItem = () => {
    const headList = [
      // {name: t('inviteRebate'), url: linkList['Invite'],pic: inviteCommission},
      // {name: t('crossChain'), url: linkList['Bridge'],pic: crossBridge},
      // {name: t('Repurchase'), url: linkList['Buyback'],pic: destory},
      {name: 'Dao', url: linkList['Dao'],pic: dao},
    ]
    return headList.map((item: any, index: number) => {
      return (
        <Content key={index} onClick={() => window.location.href = item.url}>
          <ImgIcon size='36px' src={item.pic} alt="icon"></ImgIcon>
          <ItemA style={{marginTop: '10px'}}>{item.name}</ItemA>
        </Content>
      )
    })
  }

  return (
    <PopWrap style={status ? {} : {display: 'none'} }>
      <Pop status>
        <FlexContent>
          {HeadItem()}
        </FlexContent>
        <PopContent>
          {sideRander()}
        </PopContent>

        <PopContent style={{marginTop: '13px'}}>
          <PopItem onClick={() => window.location.href = 'https://zebra.blog/#/'}>
            <LeftContent>
              <ItemImg src={domain} alt=""/>
              <ItemA>{t('ZebraDomain')}</ItemA>
            </LeftContent>
            <ImgIcon size='16px' src={arrow} alt="arrow"></ImgIcon>
          </PopItem>
        </PopContent>
      </Pop>
      <Blank onClick={() => closeHandle()}></Blank>
    </PopWrap>
  )
}

export default H5Side
