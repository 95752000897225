import React from 'react'
import styled from 'styled-components'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  margin-top: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  z-index: -1;
  display: ${({ show }) => (show ? 'block' : 'none')};
  background-color: #202020;
  padding: 20px;
  border-radius: 12px;
`

export default function AdvancedSwapDetailsDropdown({ id, trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter show={Boolean(trade)} id={id}>
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
