import { Currency, Pair } from '@mdex/zkscroll-sdk'
import React, { useState, useContext, useCallback } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { isMobile } from 'react-device-detect'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { css } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0 0.5rem 0 0.1rem' : '0')};
  ${isMobile &&
    css`
    padding: 0px 6px;
  `}
`

const CurrencySelect = styled.button<{ selected: boolean,cssBorder:any }>`
  align-items: center;
  height: 2.6rem;
  font-size: 20px;
  font-weight: 500;
  // background-color: ${({ selected, theme }) => (selected ? theme.bg1 : 'rgba(150, 98, 234, 0.2)')};
  background-color: #202020;
  // color: ${({ selected, theme }) => (selected ? theme.text1 : '#A06AFF')};
  color: #13F287;
  border-radius: 10px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  margin-right: ${({ selected }) => (selected ? '0' : '12px')};
  border: 1px solid ${({ cssBorder }) => (cssBorder ? 'none' : '#13F287')};
  ${isMobile &&
    css`
    padding: 4px 0;
    height: 30px;
    margin-right: 0;
    border-radius: 5px;
  `}
  :hover {
    // background-color: ${({ selected, theme }) => (selected ? theme.bg2 : '#A06AFF')};
    background-color: #213A2F;
    // color: ${({ selected, theme }) => (selected ? theme.text1 : '#fff')};
    color: #13F287;
  }
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
  ${!isMobile &&
    css`
    padding: 0.2rem 1rem 0 1rem;
  `}
  ${isMobile &&
    css`
    padding: 0 10px;
  `}
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

export const StyledDropDown = styled(DropDown)<{ selected: boolean, color?: string }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;
  path {
    stroke: ${({ color, selected }) => (color ? color : (selected ? '#48587B' : '#48587B'))};
    stroke-width: 1.5px;
  }
  // transform: ${({ selected }) => (selected ? 'rotateX(180deg)' : 'rotate(180deg)')};
  flex: none;
`

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  // background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`

const Container = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(255,255,255,0.2);
  padding-left: 10px;
  // opacity: 0.2;
  // background-color: ${({ theme }) => theme.bg1};
  ${isMobile &&
    css`
    padding-left: 5px;
    border-radius: 10px;
  `}
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? 'margin: 2px 0.25rem 0 0.45rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '20px' : '16px')};
  ${isMobile &&
    css`
    font-size:  14px;
    margin-left: 10px;
  `}
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background: #202020;
  border: 1px solid #13F287;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  color: #13F287;
  position: relative;
  line-height: 22px
  :hover {
    // background-color: ${({ theme }) => theme.primary1};
    background-color: #13F287;
    color: #fff;
  }
  :focus {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
  ${!isMobile &&
    css`
    font-size: 0.875rem;
    top: 13px;
    left: -10px;
  `}
  ${isMobile &&
    css`
    font-size: 12px;
    top: 18px;
    height: 24px;
    border-radius: 5px;
  `}
`

const StyledOnlyDiv = styled.div`
    float: right;
`

const RightContentDiv = styled.div`
  flex: 1;
  ${isMobile &&
    css`
    padding: 0;
  `}
  ${!isMobile &&
    css`
    padding: 0.75rem 0 0 0;
  `}
`

const NumericalInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${isMobile &&
    css`
    padding: 4px 0 4px 10px;
  `}
  ${!isMobile &&
    css`
    padding: 0 1rem;
  `}
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  cssCursor?:boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
  cssCursor
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useContext(ThemeContext)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
      <Container>
        <InputRow id='InputRow' style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          <CurrencySelect
            selected={!!currency}
            cssBorder={!!cssCursor}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={30} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={isMobile ? '20px' : '30px'} />
              ) : null}
              <Aligner>
                {pair ? (
                  <StyledTokenName className="pair-name-container">
                    {pair?.token0.symbol}/{pair?.token1.symbol}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName className="token-symbol-container" style={{color:cssCursor?'#fff':''}} active={Boolean(currency && currency.symbol)}>
                    {(currency && currency.symbol && currency.symbol.length > 7
                      ? currency.symbol.slice(0, 6) +
                        '...'
                      : currency?.symbol) || t('selectToken')}
                  </StyledTokenName>
                )}
              </Aligner>
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} color={!!currency ? '#fff' : '#fff'} />}
            </Aligner>
          </CurrencySelect>
          <RightContentDiv id="RightContentDiv">
            <NumericalInputWrapper>
              {!hideInput && (
                <>
                  {account && currency && showMaxButton && label !== 'To' && (
                    <StyledBalanceMax id='StyledBalanceMax' onClick={onMax}>MAX</StyledBalanceMax>
                  )}
                  <NumericalInput
                    className="token-amount-input"
                    value={value}
                    onUserInput={val => {
                      onUserInput(val)
                    }}
                    fontSize={isMobile? '20px' : '24px'}
                    align='right'
                  />
                </>
              )}
            </NumericalInputWrapper>
            <StyledOnlyDiv>
              {!hideInput && (
                <LabelRow>
                  <RowBetween>
                    {/* <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                        {label}
                      </TYPE.body> */}
                    {account && (
                      <TYPE.body
                        onClick={onMax}
                        color={theme.text2}
                        fontWeight={500}
                        fontSize={isMobile ? 12 :14}
                        style={{ display: 'inline', cursor: cssCursor?'pointer':'default', marginBottom:'4px', color: "rgba(255,255,255,0.5)",whiteSpace: "nowrap",maxWidth:"180px",overflow: "hidden",textOverflow: "ellipsis",}}
                      >
                        {!hideBalance && !!currency && selectedCurrencyBalance
                          ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(8)
                          : ''}
                      </TYPE.body>
                    )}
                  </RowBetween>
                </LabelRow>
              )}
            </StyledOnlyDiv>
          </RightContentDiv>
          
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
