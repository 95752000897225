import React from 'react'
import { Text, Flex } from 'rebass'
import { RowBetween } from 'components/Row'
import { useTranslation } from 'react-i18next'

import checkScanIcon from '../../assets/images/check_scan.svg'
import checkIcon from '../../assets/images/check.svg'
import unCheckIcon from '../../assets/images/unCheck.png'

export default function Check({
  address,
  isCheck,
  setIsCheck
}: {
  address: any,
  isCheck: boolean,
  setIsCheck: React.Dispatch<boolean>
}) {
  const { t } = useTranslation()
  const handleClick = () => {
    setIsCheck(!isCheck)
  }
  const handleOpen = () => {
    window.open('https://scrollscan.com/token/' + address)
  }
  return (
    <RowBetween>
      <Flex alignItems='center'>
        <img width='15px' onClick={handleClick} src={isCheck ? checkIcon : unCheckIcon} alt="uncheck" />
        <Text marginLeft='10px' fontSize='12px'>{t('IKnow')}</Text>
      </Flex>
      <Flex alignItems='center' style={{cursor: 'pointer'}} onClick={handleOpen}>
        <Text fontSize='12px' fontWeight='500' color='#13F287'>{t('viewOn')}</Text>
        <img width='12px' src={checkScanIcon} alt="checkScan" />
      </Flex>
    </RowBetween>
  )
}
