// the Uniswap Default token list lives here
// const datetime = new Date().getTime()
// const hostname = window.location.hostname
const datetime = new Date().getTime()
const hostname = window.location.origin
export const DEFAULT_TOKEN_LIST_URL = `${hostname}/tokenlist.json?t=${datetime}`

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
]
