import React from 'react'
import styled from 'styled-components'
// import { darken } from 'polished'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
// import { ExternalLink } from '../../theme'
import { _collectLink } from '../../utils/urlMethod'
import Settings from '../Settings'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
  padding-left: 14px;
  margin-bottom: 20px;
  // border-bottom: 1px solid #EBEDF3FF;
  border-radius: 0;
  ${isMobile &&
    css`
    padding-left: 1px;
    margin-bottom: 16px;
  `}
`

const activeClassName = 'ACTIVE'
// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName
// })`
// ${({ theme }) => theme.flexRowNoWrap}
// align-items: center;
// justify-content: center;
// height: 3rem;
// border-radius: 3rem;
// outline: none;
// cursor: pointer;
// text-decoration: none;
// color: #626E88FF;
// font-size: 16px;
// margin-right: 20px;
// `

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  display: none;
  height: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #202020;
  font-size: 16px;
  margin-right: 20px;
  &.${activeClassName} {
    display: block;
    font-weight: bold;
    font-size: 24px;
    position: relative;
    color: #fff;
    // &:after {
    //   content: '';
    //   width: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   height: 3px;
    //   background-color: #13F287;
    // }
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  ${isMobile && css`
  font-size: 20px;
  `}
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const HeaderElementWrap = styled.div`
  flex: 1;
  // align-items: center;
`
const RightWrap = styled.div`
  float:right;
`
// 弹窗title
export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  // const defaultLang = localStorage.getItem('i18nextLng') || 'en'
  // 链接列表
  // const linkList = _collectLink(defaultLang, '')
  return (
    <Tabs style={{ display: isMobile ? 'flex' : 'flex', width: '100%', justifyContent: 'flex-start'}}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
      {/* <StyledExternalLink id={`crossChain-nav-link`} href={linkList['Bridge']}>
        {t('crossChain')}
      </StyledExternalLink> */}
      <HeaderElementWrap>
        <RightWrap>
          <Settings />
        </RightWrap>
      </HeaderElementWrap>
    </Tabs>
  )
}

export function FindPoolTabs() {
  const { t } = useTranslation()
  return (
    <Tabs id='FindPoolTabs'>
      <RowBetween style={{ padding: '0 0 1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{t('importPool')}</ActiveText>
        <QuestionHelper text={t('text21')} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding, creating }: { adding: boolean; creating: boolean }) {
  const { t } = useTranslation()
  return (
    <Tabs id='AddRemoveTabs'>
      <RowBetween style={{ padding: '0 0 1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{creating ? t('createpair') : adding ? t('addLiquidity') : t('removeLiquidity')}</ActiveText>
        <QuestionHelper text={adding ? t('text22') : t('text23')} />
      </RowBetween>
    </Tabs>
  )
}
