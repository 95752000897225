import { Trade, TradeType } from '@mdex/zkscroll-sdk'
import React, { useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'
// import { getContract } from '../../utils'
// import abi from './getPrice.json'
// import { PRICE_ADDRESS, USDT_ADDRESS, ZEBRA_ADDRESS } from '../../constants'
// import { useActiveWeb3React } from '../../hooks'
// import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
// import { useToggleSettingsMenu } from '../../state/application/hooks'
// const InfoLink = styled(ExternalLink)`
//   width: 100%;
//   border: 1px solid ${({ theme }) => theme.bg3};
//   padding: 6px 6px;
//   border-radius: 8px;
//   text-align: center;
//   font-size: 14px;
//   color: ${({ theme }) => theme.text1};
// `

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const theme = useContext(ThemeContext)
  // const { account, library } = useActiveWeb3React()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  const inputAddress = trade.route.path[0].address
  const outputAddress = trade.route.path.slice(-1)[0].address
  const pariLength = trade.route.pairs.length
  const outputAmount = slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(8) // 交易数量
  useEffect(() => {

  }, [pariLength, inputAddress, outputAddress, outputAmount])
  const { t } = useTranslation()
  // const toggleSettings = useToggleSettingsMenu()

  return (
    <>
      <AutoColumn>
        <RowBetween align="center">
          <TYPE.black fontWeight={500} fontSize={14} color={theme.text2}>
            {t('Slippagetolerance')}
            <QuestionHelper text={t('SlippagetoleranceHelp')} />
          </TYPE.black>
          <TYPE.black fontWeight={500} fontSize={14} color={theme.text1}>
            {allowedSlippage / 100}%
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={500} color={theme.text2}>
              {t('Impact')}
            </TYPE.black>
            <QuestionHelper text={t('text12')} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={500} color={theme.text2}>
              {isExactIn ? t('minimumreceived') : t('maximumSold')}
            </TYPE.black>
            <QuestionHelper text={t('text11')} />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.text1} fontSize={14}>
            {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={500} color={theme.text2}>
              {t('liquidityFee')}
            </TYPE.black>
            {/* <QuestionHelper text={t('text13')} /> */}
          </RowFixed>
          <TYPE.black fontSize={14} >
          {realizedLPFee ? `${realizedLPFee.toSignificant(2)} ${trade.inputAmount.currency.symbol}` : '-'} <React.Fragment>
            
          </React.Fragment>
          </TYPE.black>
        </RowBetween>
        
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade,
  id?: string
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)

  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <SectionBreak />
              <AutoColumn>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                    Route
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
          {/* <AutoColumn style={{ padding: '0 24px' }}>
            <InfoLink href={'https://uniswap.info/pair/' + trade.route.pairs[0].liquidityToken.address} target="_blank">
              View pair analytics ↗
            </InfoLink>
          </AutoColumn> */}
        </>
      )}
    </AutoColumn>
  )
}
