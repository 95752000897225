import { Text } from 'rebass'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { css } from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  background-color: #202020;
  color: #fff;
  border-radius: 36px;
  ${isMobile && css`
    border-radius: 8px;
  `}
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary1};
  // border: 1px solid ${({ theme }) => theme.primary5};
  border: none;
  border-radius: 8px;
  ${!isMobile &&
    css`
    font-size: 1rem;
  `}
  ${isMobile &&
    css`
    font-size: 14px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: #000;
  :hover {
    // border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    // border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
