import React from 'react'
import { useTranslation } from "react-i18next";
import { Flex } from "rebass";
import styled from "styled-components";
import { _collectLink } from "../../utils/urlMethod";

import GithubH from "./img/Github-hover.svg";
import Github from "./img/Github.svg";
import MediumH from "./img/Medium-hover.svg";
import Medium from "./img/Medium.svg";
// import Telegram from './img/Telegram.svg'
// import TelegramH from './img/Telegram-hover.svg'
import DiscordH from "./img/Discord-hover.svg";
import Discord from "./img/Discord.svg";
import TwitterH from "./img/Twitter-hover.svg";
import Twitter from "./img/Twitter.svg";
// import Weibo from './img/Weibo.svg'
// import WeiboH from './img/Weibo-hover.svg'
import Zebra from "../../assets/images/newLogo.png";
import "./index.css";
const Footer = styled.footer`
  width: 100%;
  background-color: #121519;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 46px;
  padding-bottom: 20px;
`;
const Content = styled.div`
  display: flex;
  margin-right: 140px;
  font-size: 14px;
  flex-direction: column;
`;
const Logo = styled.div`
  width: 94px;
  height: 30px;
  background: url(${Zebra});
  background-size: 100% 100%;
  cursor: pointer;
`;
const Copyright = styled.div`
  // position: absolute;
  // left: 0;
  // top: 210px;
  color: #6d7278;
  font-size: 12px;
  white-space: nowrap;
`;
const NavIcon = styled.div<{ icon: string; iconHover: string }>`
  width: 21px;
  height: 20px;
  margin-right: 15px;
  background: ${({ icon }) => `url(${icon}) center`};
  background-size: contain;
  cursor: pointer;
  :hover {
    background: ${({ iconHover }) => `url(${iconHover}) center`};
    background-size: contain;
  }
`;
const Head = styled.div`
  // font-weight: bold;
  font-size: 15px;
  color: #ffffff;
`;

const Link = styled.a`
  margin-top: 10px;
  color: #a8a8a8;
  :hover {
    color: #fff;
    &.disabled {
      color: #a8a8a8;
    }
  }
`;

export default function FooterFun() {
  const defaultLang = localStorage.getItem("i18nextLng") || "en";
  // 链接列表
  const linkList = _collectLink(defaultLang, "");
  const { t } = useTranslation();

  const navList = [
    {
      icon: Github,
      iconHover: GithubH,
      href: linkList["Github"],
    },
    {
      icon: Medium,
      iconHover: MediumH,
      href: linkList["Medium"],
    },
    // {
    //   icon: Telegram,
    //   iconHover: TelegramH,
    //   href: linkList['Telegram']
    // },
    {
      icon: Twitter,
      iconHover: TwitterH,
      href: linkList["Twitter"],
    },
    {
      icon: Discord,
      iconHover: DiscordH,
      href: linkList["Discord"],
    },
    // {
    //   icon: Weibo,
    //   iconHover: WeiboH,
    //   href: linkList['Weibo']
    // },
  ];

  const handleLink = (link: string) => {
    window.open(link);
  };

  const navItems = navList.map((item, index) => {
    return (
      <NavIcon
        key={index}
        icon={item.icon}
        iconHover={item.iconHover}
        onClick={() => handleLink(item.href)}
      ></NavIcon>
    );
  });
  return (
    <Footer>
      <div className="footer-box">
        <div className="content-box">
          <Content style={{ position: "relative", marginRight: "200px" }}>
            <Logo onClick={() => handleLink(linkList["Home"])}></Logo>
            <Flex marginTop="20px">{navItems}</Flex>
          </Content>

          {/* <Content>
            <Head>{t('apply')}</Head>
            <Link href={linkList['ACText1']} target="_blank">{t('tokenApply')}</Link>
            <Link href={linkList['ACText2']} target="_blank">{t('liquidityApply')}</Link>
            <Link href={linkList['ACText3']} target="_blank">{t('IMOApply')}</Link>
            <Link href={linkList['Fund']} target="_blank">{t('FundApply')}</Link>
          </Content> */}

          <Content>
            <Head>{t("nav")}</Head>
            <Link href={linkList["Swap"]}>{t("swap")}</Link>
            <Link href={linkList["Pool"]}>{t("liquidityPool")}</Link>
            <Link
              aria-disabled
              style={{ cursor: "default" }}
              className="disabled"
            >
              {t("Info")}
            </Link>
            {/* <Link href={linkList['Farm']} target="_blank">{t('liquidity')}</Link>
            <Link href={linkList['Boardroom']} target="_blank">{t('boardroom')}</Link>
            <Link href={linkList['Trade']} target="_blank">{t('reward')}</Link> */}
          </Content>

          {/* <Content>
            <Head>{t('document')}</Head>
            <Link href={linkList['Faq']} target="_blank">FAQ</Link>
            <Link href={linkList['Guidance']} target="_blank">{t('userGuide')}</Link>
          </Content> */}

          <Content>
            <Head>{t("about")}</Head>
            <Link href={linkList["Docs"]} target="_blank">
              {t("document")}
            </Link>
            {/* <Link href={linkList['Notice']} target="_blank">{t('announcement')}</Link>
            <Link href={linkList['ABText2']} target="_blank">{t('adjustmentPool')}</Link>
            <Link target="_blank">{t('Fund')}</Link>
            <Link href={linkList['Vulnerability']} target="_blank">{t('Vulnerability')}</Link>
            <Link href={linkList['ContactUS2']} target="_blank">{t('contactUs')}</Link> */}
          </Content>
        </div>
        <Copyright className="tips">
          @2023 zebra.xyz. All rights reserved
        </Copyright>
      </div>
    </Footer>
  );
}
