import styled from 'styled-components'

const PopWrap = styled.aside`
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
  z-index: 9999;
`

const Pop = styled.div<{ status: boolean }>`
  position: fixed;
  transition-duration: 0.3s;
  top: 0;
  left: -325px;
  width: 325px;
  background-color: #fff;
  z-index: 9999;
  padding: 16px;
  bottom: 0;
  background-color: #F0F2F3;
  transform: translateX(${({status}) => status ? '325px' : '0'});
`

const PopItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  font-family: 'Medium';
  border-bottom: 1px solid #F8FAFC;
  cursor: pointer;
`

const ItemImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

const ImgIcon = styled.img<{size: string}>`
  width: ${({size}) => size && size};
  height: ${({size}) => size && size};
`

const ItemA = styled.div`
  font-weight: 500;
  color: #000000;
  font-size: 14px;
`

const Blank = styled.div`
  width: 64px;
  position: fixed;
  top: 0; 
  right: 0;
  bottom: 0;
  z-index: 9999;
`

const PopContent =styled.div`
  padding: 0 12px;
  background-color: #fff;
  border-radius: 10px;
`

const LeftContent = styled.div`
  display: flex;
`

const FlexContent = styled.div`
  display: flex;
  padding: 15px 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
`

const Content = styled.div`
  flex: 1;
  text-align: center;
`

export {
  PopItem,
  ItemImg,
  PopWrap,
  Blank,
  ItemA,
  Pop,
  PopContent,
  ImgIcon,
  LeftContent,
  FlexContent,
  Content
}