import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'

import warningIcon from '../../assets/images/warning.svg'
import ethWarnIcon from '../../assets/images/eth_warning.svg'

import { isMobile } from 'react-device-detect'
import { css } from 'styled-components'

export const BoxWrapper = styled.div`
  padding: 30px 20px;
  border-radius: 24px;
  background: rgba(255,255,255,0.05);
  font-size: 0;
  text-align: center;
  ${isMobile && css`
    padding: 10px 14px;
  `}
`

export default function BoxContent({
  isUnknow
}: {
  isUnknow: boolean
}) {
  const { t } = useTranslation()

  return (
    <BoxWrapper>
      <img width={isMobile?'36px':'50px'} src={isUnknow ? warningIcon : ethWarnIcon} alt="warning" />
      <Text marginTop={isMobile?'6px':'20px'} lineHeight={isMobile?'24px':'28px'}  textAlign='left' fontSize='14px' color='#fff'>{t('text36')}</Text>
      <Text marginTop={isMobile?'6px':'20px'} textAlign='left' fontSize='14px' color='#fff'>{t('text37')}</Text>
    </BoxWrapper>
  )
}
