import { ChainId, JSBI, Percent, Token, WETH } from "@mdex/zkscroll-sdk";
import { AbstractConnector } from "@web3-react/abstract-connector";

import {
  injected,
  injectedOKX,
  walletconnect /*, walletlink*/,
} from "../connectors";

const ADDRS: { [chainId: number]: { [addr: string]: string } } = {
  534351: {
    ROUTER: "0xaA3C929b4460fAe007895884ede9D1Ccd77f37d4",
    WETH: "0x833372E396496F4c6E59F025483099Bc8DbA79F3",
    USDC: "0xc0CC181BAedc547eF3427D3642671e0C4ff09941",
    USDT: "0xCd8EE7570c5382144D1c442b6c3563A40a5e3C4a",
    WBTC: "0x62B8a1eF640B96E86d6927824E5FE682d07D3b46",
  },
  534352: {
    ROUTER: "0x0122960d6e391478bfE8fB2408Ba412D5600f621",
    WETH: "0x5300000000000000000000000000000000000004",
    USDC: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
    USDT: "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df",
    WBTC: "0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1",
  },
};

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? "1");
export const ROUTER_ADDRESS = ADDRS[chainId]["ROUTER"];
export const MINT_ADDRESS = "0x7373c42502874C88954bDd6D50b53061F018422e";
export const PRICE_ADDRESS = "0x99a7e7E84aD64A6BAb105364FA0f3F7542FD0064";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const USDT_ADDRESS = "0xa71edc38d189767582c38a3145b5873052c3e47a";
export const INVITE_ADDRESS = "0x60728C9769eaF3CFa88a59C95dCd2f09877b7ddD";
export const MISSION_ADDRESS = "0xEa149e7f7e20F044954c0494464048b3c8B96dd7";

export const API_URL = "https://gateway.zebra.xyz/v1";
export const PRIVATE_KEY =
  "5K1Eod2Uwn2IWmV.RdacDjL3KKM7RqB2jaGogTqyU.OXcz9dS1uXssH1G6JEi93q";
export { PRELOADED_PROPOSALS } from "./proposals";

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export const USDC = new Token(
  chainId,
  ADDRS[chainId]["USDC"],
  18,
  "USDC",
  "USDC"
);
export const USDT = new Token(
  chainId,
  ADDRS[chainId]["USDT"],
  18,
  "USDT",
  "Tether USD"
);
export const WBTC = new Token(
  chainId,
  ADDRS[chainId]["WBTC"],
  18,
  "WBTC",
  "Wrapped BTC"
);

export const AVERAGE_BLOCK_TIME_IN_SECS = 14;
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320;
export const PROPOSAL_LENGTH_IN_SECS =
  AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS;

export const GOVERNANCE_ADDRESS = "0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F";

export const TIMELOCK_ADDRESS = "0x1a9C8182C09F50C8318d769245beA52c32BE35BC";

const UNI_ADDRESS = "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984";
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    UNI_ADDRESS,
    18,
    "UNI",
    "Uniswap"
  ),
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    UNI_ADDRESS,
    18,
    "UNI",
    "Uniswap"
  ),
};

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: "UNI",
  [GOVERNANCE_ADDRESS]: "Governance",
  [TIMELOCK_ADDRESS]: "Timelock",
};

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.TESTNET]: "0x090D4613473dEE047c3f2706764f49E0821D256e",
};

const WETH_ONLY: ChainTokenList = {
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET]],
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
};
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.TESTNET]: [...WETH_ONLY[ChainId.TESTNET], USDC, USDT, WBTC],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, WBTC],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.TESTNET]: [...WETH_ONLY[ChainId.TESTNET], USDC, USDT, WBTC],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, WBTC],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.TESTNET]: [...WETH_ONLY[ChainId.TESTNET], USDC, USDT, WBTC],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, WBTC],
};

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][];
} = {
  // [ChainId.TESTNET]: [
  //   [USDC, USDT],
  //   [DAI, USDT]
  // ]
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: "Injected",
    iconName: "arrow-right.svg",
    description: "Injected web3 provider.",
    href: null,
    color: "#010101",
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: "metamask.png",
    description: "Easy-to-use browser extension.",
    href: null,
    color: "#E8831D",
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconName: "walletConnectIcon.svg",
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
    color: "#4196FC",
    mobile: true,
  },
  OKX: {
    connector: injectedOKX,
    name: "OKX",
    iconName: "okx.png",
    description: "Easy-to-use browser extension.",
    href: "",
    color: "#E8831D",
  },
  COIN98: {
    connector: injected,
    name: "Coin98",
    iconName: "coin98.png",
    description: "Easy-to-use browser extension.",
    href: "",
    color: "#E8831D",
  },
  GATE: {
    connector: injected,
    name: "Gate Wallet",
    iconName: "gate.png",
    description: "Easy-to-use browser extension.",
    href: "",
    color: "#E8831D",
  },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // SAFEPAL_WALLET: {
  //   connector: walletconnect,
  //   name: 'SafePal Wallet',
  //   iconName: 'safePalWalletIcon.png',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  // BITKIP_WALLET: {
  //   connector: injected,
  //   name: 'Bitkeep Wallet',
  //   iconName: 'bitkeepWallet.png',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC'
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
};

export const NetworkContextName = "NETWORK";

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7);

export const BIG_INT_ZERO = JSBI.BigInt(0);

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE
); // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(
  JSBI.BigInt(10),
  JSBI.BigInt(16)
); // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(
  JSBI.BigInt(75),
  JSBI.BigInt(10000)
);

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  "0x7F367cC41522cE07553e823bf3be79A889DEbe1B",
  "0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b",
  "0x901bb9583b24D97e995513C6778dc6888AB6870e",
  "0xA7e5d5A720f06526557c513402f2e6B5fA20b008",
];
