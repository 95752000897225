import React, { useContext, useRef, useState } from 'react'
import { X } from 'react-feather'
import { Text } from 'rebass'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import i18next from 'i18next';
import styled, { ThemeContext } from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import {
  // useDarkModeManager,
  useExpertModeManager,
  useUserTransactionTTL,
  useUserSlippageTolerance
} from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'
import Discord_h5 from '../../assets/images/Discord_h5.png'
import Github_h5 from '../../assets/images/Github_h5.png'
// import Weibo_h5 from '../../assets/images/Weibo_h5.svg'
import Twitter_h5 from '../../assets/images/Twitter_h5.png'
import Medium_h5 from '../../assets/images/Medium_h5.png'
// import Telegram_h5 from '../../assets/images/Telegram_h5.png'

import Setting from '../../assets/svg/setting.svg'

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  height: 26px;
  width: 26px;
  border: none;
  background: ${`url(${Setting}) center`};
  background-size: cover;
  cursor: pointer;
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;
  border: 1px solid rgba(255,255,255,0.2);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 19.125rem;
  `};
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,0.2);
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`
const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 8px;
  `};
  font-size: 1rem;
  width: 50%;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`
const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  border: 1px solid ${({ active, theme }) => active? '' : 'rgba(255,255,255,0.2)'};
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? "#000" : theme.text1)};
`

const IconsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
const IconInfo = styled.a`
  width: 50%;
  margin-top:20px;
  display: flex;
`


export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()
  const {t} = useTranslation()
  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [ttl, setTtl] = useUserTransactionTTL()

  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const defaultLang = localStorage.getItem('i18nextLng') || 'en'
  const [language, setLanguage] = useState(defaultLang);
  // const [darkMode, toggleDarkMode] = useDarkModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)
  let match = useRouteMatch('/invite') || useRouteMatch('/referral')

  useOnClickOutside(node, open ? toggle : undefined)

  const history = useHistory()

  const handleLangChange = (lang:string) => {
    setLanguage(lang)
    i18next.changeLanguage(lang)
    if (!match) {
      history.push({pathname: `/swap?lang=${lang}`}) 
    }
  }

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={20}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </Text>
              <Text fontWeight={600} fontSize={20}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        {expertMode ? (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        ) : null}
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <AutoColumn gap="sm" style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14} color={'#fff'}>
              {t('transactionSettings')}
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={ttl}
              setDeadline={setTtl}
            />
            <Text fontWeight={400} fontSize={14} color={'#fff'}>
              {t('interfaceSettings')}
            </Text>
            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  {t('toggleExpertMode')}
                </TYPE.black>
                <QuestionHelper text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode()
                        setShowConfirmation(false)
                      }
                    : () => {
                        toggle()
                        setShowConfirmation(true)
                      }
                }
              />
            </RowBetween>
            {
              isMobile && <React.Fragment>
                <RowBetween>
                  <RowFixed>
                    <TYPE.black fontWeight={400} fontSize={14}>
                      {t('langSet')}
                    </TYPE.black>
                  </RowFixed>
                </RowBetween>
                <RowBetween>
                  <Option
                  onClick={() => handleLangChange('en')}
                  active={language == 'en'}
                  >
                    English
                  </Option>
                  <Option
                  onClick={() => handleLangChange('zh-CN')}
                  active={language == 'zh-CN'}
                  >
                    中文
                  </Option>
                </RowBetween>
                <IconsList className='IconsList'>
                  <IconInfo href="https://github.com/zebra-xyz" target="_blank">
                    <img src={Github_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#fff'>Github</Text>
                  </IconInfo>
                  <IconInfo href="https://medium.com/@ZebraProtocol" target="_blank">
                    <img src={Medium_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#fff'>Medium</Text>
                  </IconInfo>
                  {/* <IconInfo href={`https://t.me/${defaultLang == 'en' ? 'ZebraEN' : 'Zebra' }`} target="_blank">
                    <img src={Telegram_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#fff'>Telegram</Text>
                  </IconInfo> */}
                  <IconInfo href="https://twitter.com/ZebraProtocol" target="_blank">
                    <img src={Twitter_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#fff'>Twitter</Text>
                  </IconInfo>
                  <IconInfo href="https://discord.gg/C8zj76eyMm" target="_blank">
                    <img src={Discord_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#fff'>Discord</Text>
                  </IconInfo>
                  {/* <IconInfo href="https://weibo.com/u/xxx">
                    <img src={Weibo_h5} width={20} height={20} style={{marginRight: '20px'}} /><Text fontWeight={800} fontSize={14} color='#48587B'>Weibo</Text> 
                  </IconInfo> */}
                </IconsList>
              </React.Fragment>
            }
            
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
