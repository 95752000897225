import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@mdex/zkscroll-sdk'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { injected,walletconnect } from '../connectors'
import { NetworkContextName } from '../constants'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('lastconn') == 'wc') {
      activate(walletconnect, undefined, true).catch(() => {
        setTried(true)
      })
    } else {
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does
  const { ethereum } = window
  const { chainId } = useActiveWeb3React()
  // const { account } = useWeb3ReactCore()
  const setChain = () => {
    if (ethereum && ethereum.request) {
      try {
        ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: "0x82750",
              rpcUrls: ["https://rpc.scroll.io/"],
              chainName: "Scroll",
              nativeCurrency: {
                name: "Scroll",
                symbol: "ETH",
                decimals: 18
              },
              blockExplorerUrls: ["https://scrollscan.com/"]
            }
          ]
        })
      } catch (ex) {
        // console.log('Scroll installed')
      }
    }
  }
  const checkChain = () => {
    // if(chainId != 1) {
    //   window.location.reload()
    // }
  }
  useEffect(() => {
    if(ethereum && ethereum.on && chainId) {
      ethereum.on('chainChanged', checkChain)
    }
    // console.error('setChain#',error,chainId,!((chainId??1) in ChainId))
    if(error || (chainId && !((chainId??1) in ChainId))) {
      setChain()
    }
    if (ethereum && ethereum.on && !active && !error && !suppress && chainId) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate, chainId])
}
