import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
// import Home from '../../assets/images/home.png'
import Exchange_active from '../../assets/images/exchange-active.png'
import Exchange from '../../assets/images/exchangeTab.png'
import Vibrate from '../../assets/images/vibrate.png'
import Vibrate_active from '../../assets/images/vibrate-active.png'
import tabInfo from '../../assets/images/tabInfo.png'
import Bridge from '../../assets/images/bridge.png'
import Doc from '../../assets/images/doc.png'
// import Toast from '../Toast/index';
import { _collectLink } from '../../utils/urlMethod'
import Modal from '../Modal'
import { CloseIcon } from '../../theme'
import ScroollIcon from '../../assets/images/scrooll-icon.svg'
import GeckoIcon from '../../assets/svg/geckoterminal icon.svg'
import RhinoIcon from '../../assets/images/rhino-icon.svg'
// import owltoIcon from '../../assets/images/owlto-icon.svg'
import shareImg from '../../assets/svg/share.svg'
import OrbiterIcon from '../../assets/svg/orbiter.svg'

const Warper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  display: flex;
  z-index: 2;
  img {
    width: 24px;
  }
  padding: 8px 0px 35px 0px;
  text-align: center;
  a {
    text-decoration: none;
  }
`
// const NavEle = styled.div`
//   flex:1;
// `
const NavLinkNav = styled(NavLink)`
  position: relative;
  flex:1;
`
const NavLinkA = styled.a`
  position: relative;
  flex:1;
`
const ModalBox = styled.div`
width:100%;
.modal-title{
  padding:16px 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(60, 60, 67, 0.3);
}
.list{
  padding: 24px;
  display: flex;
  flex-direction: column;
  a{
    color: #fff;
    font-size: 16px;
    margin: 15px 0;
    img{margin-right: 12px;}
    ::after{
      position: relative;
      top:2px;
      margin-left: 18px;
      display: inline-block;
      content: '';
      width: 14px;
      height: 14px;
      background: ${`url(${shareImg})`};
      background-size: 100% 100%;
      opacity: 0.7;
    }
  }
}
`
export default function NavBottom() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMoreOpen, setModalMoreOpen] = useState(false)
  const defaultLang = localStorage.getItem('i18nextLng') || 'en'
  // 链接列表
  const linkList = _collectLink(defaultLang, '')
  const [curr,setCurr]  = useState('');
  // const [isOpen, setIsOpen] = useState(false);
  // const goInfo = ()=>{

  //   setIsOpen(true);
  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 3000);
  // }
  return (
    <>
    <Warper className='Warper'>
      {/* <NavLink href={linkList['Home']}>
        <img src={Home} />
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>{t('tab1')}</Text>
      </NavLink> */}
      <NavLinkNav to={`/swap?lang=${defaultLang}`} isActive={(match,{pathname})=>{
        setCurr(pathname)
        return false;
      }}>
        <img src={curr==='/swap'? Exchange_active : Exchange} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color={curr==='/swap'?'#13f287':'#8890A1FF'}>{t('tab2')}</Text>
      </NavLinkNav>
      <NavLinkNav to={`/pool?lang=${defaultLang}`} isActive={(match,{pathname})=>{
        setCurr(pathname)
        return false;
      }}>
        <img src={curr==='/pool' ? Vibrate_active : Vibrate} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color={curr==='/pool'?'#13f287':'#8890A1FF'}>{t('pool')}</Text>
      </NavLinkNav>
      {/*<NavLinkA href={linkList['Info']}>*/}
      {/*  <img src={tabInfo} style={{marginBottom:'3px'}}/>*/}
      {/*  <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>{t('info')}</Text>*/}
      {/*</NavLinkA>*/}
      {/* <NavLinkA href={linkList['Bridge']}>
        <img src={Bridge} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>{t('crossChain')}</Text>
      </NavLinkA> */}
      <div onClick={()=>{setModalMoreOpen(true)}} style={{flex:'1'}}>
        <img src={tabInfo} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>{t('info')}</Text>
      </div>
      <div onClick={()=>{setModalOpen(true)}} style={{flex:'1'}}>
        <img src={Bridge} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>{t('crossChain')}</Text>
      </div>


      <NavLinkA href={linkList['Docs']}>
        <img src={Doc} style={{marginBottom:'3px'}}/>
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF' >{t('Docs')}</Text>
      </NavLinkA>
      {/* <NavLink href={linkList['IMO']}>
        <img src={Imo} />
        <Text fontWeight='blod' fontSize={12} color='#8890A1FF'>IMO</Text>
      </NavLink> */}
        {/* {isOpen && <Toast message={'Coming soon'} />} */}
    </Warper>
    <Modal isOpen={modalOpen} onDismiss={() => setModalOpen(false)} maxHeight={80} minHeight={60} existBottom={true}>
      <ModalBox>
        <div className='modal-title'>
        <Text fontWeight={800} fontSize={18}>
          {t('crossChain')}
        </Text>
        <CloseIcon onClick={() => setModalOpen(false)} />
        </div>
        <div className='list'>
          <NavLinkA href='https://scroll.io/bridge'>
            <img src={ScroollIcon} alt="" />Scroll Bridge
          </NavLinkA>
          <NavLinkA href='https://www.orbiter.finance/?source=Ethereum&dest=Scroll&token=ETH'>
            <img width={20} src={OrbiterIcon} alt="" />Orbiter Bridge
          </NavLinkA>
          <NavLinkA href='https://app.rhino.fi/bridge'>
            <img src={RhinoIcon} alt="" />Rhino Bridge
          </NavLinkA>
        </div>
      </ModalBox>
    </Modal>

    <Modal isOpen={modalMoreOpen} onDismiss={() => setModalMoreOpen(false)} maxHeight={80} minHeight={60} existBottom={true}>
      <ModalBox>
        <div className='modal-title'>
        <Text fontWeight={800} fontSize={18}>
          
        </Text>
        <CloseIcon onClick={() => setModalMoreOpen(false)} />
        </div>
        <div className='list'>
          <NavLinkA href={linkList['Info']}>
            <img width={20} src={tabInfo} alt="" />{t('info')}
          </NavLinkA>
          <NavLinkA href='https://www.geckoterminal.com/scroll/zebra-scroll/pools'>
            <img width={20} src={GeckoIcon} alt="" />Geckoterminal
          </NavLinkA>

        </div>
      </ModalBox>
    </Modal>
  </>
  )
}
