import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from '@mdex/zkscroll-sdk'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { isMobile } from 'react-device-detect'
import { css } from 'styled-components'
import FullPositionCard from '../../components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { RowBetween } from '../../components/Row'
import { ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots, Wrapper } from '../../components/swap/styleds'
// import { DataCard } from '../../components/earn/styled'
import EmptyProposalsIcon from '../../assets/images/empty-proposals.svg'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'
import AppBody from 'pages/AppBody'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

// const VoteCard = styled(DataCard)`
//   box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.06);
//   background-color: #fff;
// `

export const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

// const ButtonRow = styled(RowFixed)`
//   gap: 8px;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 100%;
//     flex-direction: row-reverse;
//     justify-content: space-between;
//   `};
// `

// const ResponsiveButtonPrimary = styled(ButtonPrimary)`
//   width: fit-content;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 48%;
//   `};
// `

export const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
  border: none;
  color: #fff;
  padding: 0 0 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 96px;
  `};
  &:hover {
    border: none;
  }
  &:active {
    box-shadow: none;
    border: none;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
  ${isMobile &&
    css`
    background-color: rgba(255,255,255,0.1);
    color: #fff;
    padding-bottom: 0;
    height: 32px;
    font-size: 12px;
  `}
`

const EmptyProposals = styled.div`
  padding: 16px 12px;
  min-height: 240px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const NavRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  ${isMobile &&
    css`
    justify-content: space-around;
  `}
`

const pcStyle = { color: '#00D46F', borderRadius: 0, borderBottom: '2px solid #00D46F' }
const mobileStyle = {borderRadius: 10, color: '#000', backgroundColor: '#00D46F'}
export const activeProps = {
  style: isMobile ? mobileStyle : pcStyle
}
export const initProps = {
  style: { borderRadius: isMobile ? 10 : 0, color: '#fff' },
}

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })
  return (
    <>
    <AppBody>
      <SwapPoolTabs active={'pool'} />
      <Wrapper>
        <PageWrapper>
          <NavRow id='PoolNavRow'>
            <ResponsiveButtonSecondary {...activeProps}>{t('yourliquidity')}</ResponsiveButtonSecondary>
            <ResponsiveButtonSecondary as={Link} {...initProps} to="/create/ETH">
              {t('createpair')}
            </ResponsiveButtonSecondary>
            <ResponsiveButtonSecondary as={Link} {...initProps} to="/add/ETH">
              {t('addLiquidity')}
            </ResponsiveButtonSecondary>
          </NavRow>
          {/* <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.black fontWeight={600}>{t('text16')}</TYPE.black>
              </RowBetween>
              <RowBetween>
                <TYPE.black fontSize={14} color="#878787">
                  {t('text17')}
                </TYPE.black>
              </RowBetween>
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://uniswap.org/docs/v2/core-concepts/pools/"
              >
                <TYPE.white fontSize={14}>Read more about providing liquidity</TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
        </VoteCard> */}

          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              {!account ? (
                <Card padding="40px">
                  <TYPE.body color={theme.text2} textAlign="center">
                    Connect to a wallet to view your liquidity. 
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <TYPE.body color={theme.text2} textAlign="center">
                    <Dots>Loading</Dots>
                  </TYPE.body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <>
                  {/* <ButtonSecondary>
                  <RowBetween>
                    <ExternalLink href={'https://uniswap.info/account/' + account}>
                      Account analytics and accrued fees
                    </ExternalLink>
                    <span> ↗</span>
                  </RowBetween>
                </ButtonSecondary> */}
                  {v2PairsWithoutStakedAmount.map(v2Pair => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                  {stakingPairs.map(
                    (stakingPair, i) =>
                      stakingPair[1] && ( // skip pairs that arent loaded
                        <FullPositionCard
                          key={stakingInfosWithBalance[i].stakingRewardAddress}
                          pair={stakingPair[1]}
                          stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                        />
                      )
                  )}
                </>
              ) : (
                <EmptyProposals>
                  <img src={EmptyProposalsIcon} alt={'EmptyProposalsIcon icon'}  width={60}/>
                  <TYPE.body color={theme.text2} style={{ paddingTop: '0.5rem' }} textAlign="center">
                    {t('text19')}
                  </TYPE.body>
                </EmptyProposals>
              )}

              <AutoColumn justify={'center'} gap="md">
                <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0',color:'#fff' }}>
                  {t('text20')}{' '}
                  <StyledInternalLink id="import-pool-link" to={'/find'}>
                    {t('import')}
                  </StyledInternalLink>
                </Text>
              </AutoColumn>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
      </Wrapper>
    </AppBody>
    </>
  )
}
