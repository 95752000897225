import React from 'react'
import { Currency, Price } from '@mdex/zkscroll-sdk'
import { useContext } from 'react'
import RepeatIcon from '../../assets/images/repeat.svg'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { Field } from '../../state/mint/actions'

interface PoolPriceProps {
  price?: Price
  currencies: { [field in Field]?: Currency }
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

export default function PoolPriceProps({ currencies, price, showInverted, setShowInverted }: PoolPriceProps) {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `1 ${currencies[Field.CURRENCY_A]?.symbol} = ${formattedPrice} ${currencies[Field.CURRENCY_B]?.symbol}`
    : `1 ${currencies[Field.CURRENCY_B]?.symbol} = ${formattedPrice} ${currencies[Field.CURRENCY_A]?.symbol}`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          <IconWrapper size={16} onClick={() => setShowInverted(!showInverted)} style={{marginRight: '10px'}}>
            <img src={RepeatIcon} alt={'repeat logo'} />
          </IconWrapper>
          {label}
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
