import type WalletConnectProvider from '@walletconnect/ethereum-provider'
import { AbstractConnector } from '@web3-react/abstract-connector';
import type { ProviderRpcError } from './wc2-types'
import EventEmitter3 from 'eventemitter3'

import { ArrayOneOrMore, getBestUrlMap, getChainsWithDefault, isArrayOneOrMore } from './wc2-utils'
import { ConnectorUpdate } from '@web3-react/types';

export const URI_AVAILABLE = 'URI_AVAILABLE'
const DEFAULT_TIMEOUT = 5000

/**
 * Options to configure the WalletConnect provider.
 * For the full list of options, see {@link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization WalletConnect documentation}.
 */
export type WalletConnectOptions = Omit<Parameters<typeof WalletConnectProvider.init>[0], 'rpcMap'> & {
  /**
   * Map of chainIds to rpc url(s). If multiple urls are provided, the first one that responds
   * within a given timeout will be used. Note that multiple urls are not supported by WalletConnect by default.
   * That's why we extend its options with our own `rpcMap` (@see getBestUrlMap).
   */
  rpcMap?: { [chainId: number]: string | string[] }
  /** @deprecated Use `rpcMap` instead. */
  rpc?: { [chainId: number]: string | string[] }
  relayUrl?: string
}

/**
 * Necessary type to interface with @walletconnect/ethereum-provider@2.9.2 which is currently unexported
 */
type ChainsProps =
  | {
      chains: ArrayOneOrMore<number>
      optionalChains?: number[]
    }
  | {
      chains?: number[]
      optionalChains: ArrayOneOrMore<number>
    }

/**
 * Options to configure the WalletConnect connector.
 */
export interface WalletConnectConstructorArgs {
  /** Options to pass to `@walletconnect/ethereum-provider`. */
  options: WalletConnectOptions
  /** The chainId to connect to in activate if one is not provided. */
  defaultChainId?: number
  /**
   * @param timeout - Timeout, in milliseconds, after which to treat network calls to urls as failed when selecting
   * online urls.
   */
  timeout?: number
  /**
   * @param onError - Handler to report errors thrown from WalletConnect.
   */
  onError?: (error: Error) => void
}

export class WalletConnect extends AbstractConnector {
  
  /** {@inheritdoc Connector.provider} */
  public provider?: WalletConnectProvider
  public readonly events = new EventEmitter3()

  private readonly options: Omit<WalletConnectOptions, 'rpcMap' | 'chains'>

  private readonly rpcMap?: Record<number, string | string[]>
  private readonly chains: number[] | ArrayOneOrMore<number> | undefined
  private readonly optionalChains: number[] | ArrayOneOrMore<number> | undefined
  private readonly defaultChainId?: number
  private readonly timeout: number

  private eagerConnection?: Promise<WalletConnectProvider>

  constructor({defaultChainId, options, timeout = DEFAULT_TIMEOUT, onError }: WalletConnectConstructorArgs) {
    super({
      supportedChainIds: options.chains
      })

    const { rpcMap, rpc, ...rest } = options

    this.options = rest
    this.defaultChainId = defaultChainId
    this.rpcMap = rpcMap || rpc
    this.timeout = timeout

    const { chains, optionalChains } = this.getChainProps(rest.chains, rest.optionalChains, defaultChainId)
    this.chains = chains
    this.optionalChains = optionalChains
    // this.isomorphicInitialize()

    this.chainChangedListener = this.chainChangedListener.bind(this)
    this.accountsChangedListener = this.accountsChangedListener.bind(this)
    this.disconnectListener = this.disconnectListener.bind(this)
  }

  getProvider(): Promise<any> {
    return Promise.resolve(this.provider)
  }
  async getChainId(): Promise<string | number> {
    console.log('wc2# getChainId', this.defaultChainId??'')
    try {
      return Promise.resolve(this.defaultChainId??'')
    } catch (ex) {
      return Promise.reject(ex)
    }
  }
  async getAccount(): Promise<string | null> {
    // return Promise.resolve(this.provider?.accounts[0] ?? '');
    try {
      const accounts: string[] | undefined = await this.provider?.request({ method: 'eth_requestAccounts' })
      console.log('wc2# getAccount', accounts ? accounts[0] : '')
      return Promise.resolve(accounts ? accounts[0] : '')
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private disconnectListener = (error: ProviderRpcError) => {
    console.log('wc2# disconnectListener')
    this.emitDeactivate();
  }

  private chainChangedListener = (chainId: string): void => {
    console.log('wc2# chainChangedListener', { chainId: Number.parseInt(chainId, 16), provider:this.provider })
    this.emitUpdate({ chainId: Number.parseInt(chainId, 16), provider:this.provider })
  }

  private accountsChangedListener = (accounts: string[]): void => {
    console.log('wc2# accountsChangedListener', { account: accounts[0] })
    this.emitUpdate({ account: accounts[0] })
  }

  // private URIListener = (uri: string): void => {
  //   console.log('wc2# URIListener')
  //   this.events.emit(URI_AVAILABLE, uri)
  // }

  private getChainProps(
    chains: number[] | ArrayOneOrMore<number> | undefined,
    optionalChains: number[] | ArrayOneOrMore<number> | undefined,
    desiredChainId: number | undefined = this.defaultChainId
  ): ChainsProps {
    // Reorder chains and optionalChains if necessary
    const orderedChains = getChainsWithDefault(chains, desiredChainId)
    const orderedOptionalChains = getChainsWithDefault(optionalChains, desiredChainId)

    // Validate and return the result.
    // Type discrimination requires that we use these typeguard checks to guarantee a valid return type.
    if (isArrayOneOrMore(orderedChains)) {
      return { chains: orderedChains, optionalChains: orderedOptionalChains }
    } else if (isArrayOneOrMore(orderedOptionalChains)) {
      return { chains: orderedChains, optionalChains: orderedOptionalChains }
    }

    throw new Error('Either chains or optionalChains must have at least one item.')
  }

  private async initializeProvider(
    desiredChainId: number | undefined = this.defaultChainId
  ): Promise<WalletConnectProvider> {
    const rpcMap = this.rpcMap ? await getBestUrlMap(this.rpcMap, this.timeout) : undefined
    const chainProps = this.getChainProps(this.chains, this.optionalChains, desiredChainId)
    // const chains = desiredChainId ? getChainsWithDefault(this.chains, desiredChainId) : this.chains
    const providerOpts = {
      ...this.options,
      ...chainProps,
      rpcMap: rpcMap,
    }

    const ethProviderModule = await import('@walletconnect/ethereum-provider')
    this.provider = await ethProviderModule.default.init(providerOpts)

    return this.provider
      .on('disconnect', this.disconnectListener)
      .on('chainChanged', this.chainChangedListener)
      .on('accountsChanged', this.accountsChangedListener)
      // .on('display_uri', this.URIListener)

  }

  private isomorphicInitialize(
    desiredChainId: number | undefined = this.defaultChainId
  ): Promise<WalletConnectProvider> {
    if (this.eagerConnection) return this.eagerConnection
    return (this.eagerConnection = this.initializeProvider(desiredChainId))
  }

  /** {@inheritdoc Connector.connectEagerly} */
  public async connectEagerly(): Promise<void> {
    try {
      const provider = await this.isomorphicInitialize()
      // WalletConnect automatically persists and restores active sessions
      if (!provider.session) {
        throw new Error('No active session found. Connect your wallet first.')
      }
      // this.actions.update({ accounts: provider.accounts, chainId: provider.chainId })
    } catch (error) {
      await this.deactivate()
      // cancelActivation()
      throw error
    }
  }

  /**
   * @param desiredChainId - The desired chainId to connect to.
   */
  // public async activate(desiredChainId?: number): Promise<void> {
  public async activate(): Promise<ConnectorUpdate<string | number>> {
    const provider = await this.isomorphicInitialize()
    const desiredChainId = this.defaultChainId

    if (provider.session) {
      if (!desiredChainId || desiredChainId === provider.chainId) return {
        provider:provider,
        chainId: desiredChainId,
        account: provider.accounts[0]
      }
      // WalletConnect exposes connected accounts, not chains: `eip155:${chainId}:${address}`
      const isConnectedToDesiredChain = provider.session.namespaces.eip155.accounts.some((account) =>
        account.startsWith(`eip155:${desiredChainId}:`)
      )
      if (!isConnectedToDesiredChain) {
        if (this.options.optionalChains?.includes(desiredChainId)) {
          throw new Error(
            `Cannot activate an optional chain (${desiredChainId}), as the wallet is not connected to it.\n\tYou should handle this error in application code, as there is no guarantee that a wallet is connected to a chain configured in "optionalChains".`
          )
        }
        throw new Error(
          `Unknown chain (${desiredChainId}). Make sure to include any chains you might connect to in the "chains" or "optionalChains" parameters when initializing WalletConnect.`
        )
      }
      await provider.request<void>({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${desiredChainId.toString(16)}` }],
      })
      return {
        provider:provider,
        chainId: desiredChainId,
        account: provider.accounts[0]
      }
    }

    try {
      const map: Record<string, string> = {}
      this.chains?.forEach(chain => {
        if (this.rpcMap && this.rpcMap[chain]) {
          map[chain.toString()] = this.rpcMap[chain] as string;
        }
      })
      console.log('wc2# rpcMap', map)
      await provider.connect({chains: this.chains, rpcMap: map})
      provider.signer.setDefaultChain('eip155:'+desiredChainId, map[''+desiredChainId])
      console.log('wc2# defaultChain', provider.signer.rpcProviders['eip155'].getDefaultChain())
      // this.emitUpdate({ chainId: desiredChainId, account: provider.accounts[0] })
      return {
        provider: provider,
        chainId: desiredChainId,
        account: provider.accounts[0]
      }
      // this.actions.update({ chainId: provider.chainId, accounts: provider.accounts })
    } catch (error) {
      await this.deactivate()
      // cancelActivation()
      throw error
    }
  }

  /** {@inheritdoc Connector.deactivate} */
  public async deactivate(): Promise<void> {
    console.log('wc2# deactivate')
    this.provider
      ?.removeListener('disconnect', this.disconnectListener)
      .removeListener('chainChanged', this.chainChangedListener)
      .removeListener('accountsChanged', this.accountsChangedListener)
      // .removeListener('display_uri', this.URIListener)
      .disconnect()
    this.provider = undefined
    this.eagerConnection = undefined

    this.emitDeactivate()
  }

  public async close(): Promise<void> {
    return this.deactivate();
  }

}