/**
 * 获取图标地址
 * @param {String} la   // 语言
 * @param {String} sky   // BSC,SCROLL,ETH链名称
 */
const _collectLink = (la: string, sky: string) => {
  const sk = sky ? sky : "Scroll";
  let hostname = window.location.hostname.replace("eth.", "");
  const setlangPram = `?lang=${la}`;
  return {
    // ['Footer', 'Menu']
    Github: "https://github.com/zebra-xyz",
    // ['Footer', 'Menu']
    Medium: "https://medium.com/@ZebraProtocol",
    // ['Footer', 'Menu']
    Telegram: `https://t.me/${la === "zh-CN" ? "MixDex" : "ZebraEN"}`,
    // ['Footer', 'Menu']
    Twitter: "https://twitter.com/ZebraProtocol",
    // ['Footer', 'Menu']
    Discord: "https://discord.gg/C8zj76eyMm",
    // Zebra生态基金申请
    Fund:
      "https://docs.google.com/forms/d/e/1FAIpQLSdn9wYhf_jI01nNORQXWgiyGSPKcVTP4fCq2cLYBxINd35vuw/viewform",
    // FAQ
    Faq: `https://zebradoc.gitbook.io/doc/${
      la === "zh-CN" ? "/v/chinese" : ""
    }/faq/${sk.toLowerCase()}-ban`,
    // 公告
    Notice: `https://zebradoc.gitbook.io/doc${
      la === "zh-CN" ? "/v/chinese" : ""
    }/gong-gao-1`,
    // 首页['Header', 'Menu', 'NavBottom']
    Home: `https://${hostname}/#/${setlangPram}`,
    // 兑换
    Swap: `/#/swap${setlangPram}`,
    // 流动性挖矿['Header', 'NavBottom']
    Farm: `https://${hostname}/#/liquidity${setlangPram}`,
    // 董事会['Header', 'NavBottom']
    Boardroom: `https://${hostname}/#/boardroom${setlangPram}`,
    // IMO['Header']
    IMO: `https://imo.${hostname}/#/${setlangPram}`,
    // info['Header', 'Menu', 'NavBottom']
    Chart: `${
      sk === "Bsc"
        ? `https://bsc-info.${hostname}/`
        : `https://info.${hostname}/`
    }`,
    Apply:
      "https://docs.google.com/forms/d/e/1FAIpQLSem595jWpO5_A5fRj9W4-wX_3mg3ypqqNiXcC_yi-tpcbOaqA/viewform",
    Fluidity:
      "https://docs.google.com/forms/d/e/1FAIpQLSem595jWpO5_A5fRj9W4-wX_3mg3ypqqNiXcC_yi-tpcbOaqA/viewform",
    Portal: "https://forms.gle/b6SCc4cXzn7apFhk7",
    Liquidity: `/#/add/ETH${setlangPram}`,
    // 上币申请
    ACText1:
      "https://docs.google.com/forms/d/e/1FAIpQLSem595jWpO5_A5fRj9W4-wX_3mg3ypqqNiXcC_yi-tpcbOaqA/viewform",
    // 申请流动性激励
    ACText2:
      "https://docs.google.com/forms/d/e/1FAIpQLSem595jWpO5_A5fRj9W4-wX_3mg3ypqqNiXcC_yi-tpcbOaqA/viewform",
    // IMO申请
    ACText3: "https://forms.gle/b6SCc4cXzn7apFhk7",
    // 用户使用指南
    Guidance: `https://Zebradoc.gitbook.io/doc${
      la === "zh-CN" ? "/v/chinese" : ""
    }/yong-hu-zhi-nan`,
    // 流动性池
    Pool: `/#/pool${setlangPram}`,
    // 交易挖矿['Header']
    Trade: `https://${hostname}/#/trading${setlangPram}`,
    // 矿池调整['Header']
    // 'ABText2': `https://Zebracom.zendesk.com/hc/${la === 'zh-CN' ? 'zh-cn' : 'en-gb'}/sections/360012110672${la === 'en' && '-Adjustment-of-Pool'}`,
    ABText2: `https://Zebradoc.gitbook.io/doc/${
      la === "zh-CN" ? "v/chinese/" : ""
    }gong-gao-1`,
    // 跨链桥['Header']
    Bridge: `https://scroll.io/bridge`,
    Info: `https://info.zebra.xyz`,
    // 漏洞赏金计划
    Vulnerability: `https://${hostname}/#/priceplan${setlangPram}`,
    // 回购销毁/燃烧黑洞
    Buyback: `https://${hostname}/#/buyback${setlangPram}`,
    // 新行情
    Quotes: `${`https://trade.${hostname}/`}`,
    // h5新行情
    MQuotes: `${`https://trade.${hostname}/#/quotes`}`,
    // Dao
    Dao: `${`https://dao.${hostname}/#/${setlangPram}`}`,
    // 邀请人页面
    Invite: `https:/eth.${hostname}/#/invite${setlangPram}`,
    // 被邀请人页面
    Referral: `https://${hostname}/#/referral${setlangPram}`,
    // 邀请功能规则说明
    InviteRules:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/gong-gao-1/Zebrabsc-yao-qing-gong-neng-gui-ze-shuo-ming"
        : "https://Zebradoc.gitbook.io/doc/gong-gao-1/Zebra-bsc-referral-system-rules",
    // 邀请返现规则说明
    InviteeRules:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/gong-gao-1/Zebrabsc-yao-qing-fan-xian-gui-ze-shuo-ming"
        : "https://Zebradoc.gitbook.io/doc/gong-gao-1/Zebra-bsc-referral-system-invitee-rewards-rules",
    // KOL 申请表单
    FormKOL:
      la === "zh-CN"
        ? "https://sourl.cn/QqZmh4"
        : "https://forms.gle/D9hfHjSZrs1BeMCK6",
    Mission1:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/yong-hu-zhi-nan/jiao-yi"
        : "https://Zebradoc.gitbook.io/doc/yong-hu-zhi-nan/jiao-yi",
    Mission2:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/yong-hu-zhi-nan/untitled"
        : "https://Zebradoc.gitbook.io/doc/yong-hu-zhi-nan/untitled",
    Mission3:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/yong-hu-zhi-nan/dong-shi-hui-suo-cang-gong-neng-zhi-nan"
        : "https://Zebradoc.gitbook.io/doc/yong-hu-zhi-nan/boardroom-lockup-tutorial",
    Mission4:
      la === "zh-CN"
        ? "https://Zebradoc.gitbook.io/doc/v/chinese/yong-hu-zhi-nan/kua-lian"
        : "https://Zebradoc.gitbook.io/doc/yong-hu-zhi-nan/kua-lian",
    // 联系我们
    ContactUS2: `https://Zebradoc.gitbook.io/doc/${
      la === "zh-CN" ? "v/chinese/" : ""
    }lian-xi-wo-men`,
    // 官方微博
    Weibo: "https://weibo.com/u/7627233529",
    // 域名地址
    Domain: "https://Zebra.blog/#/",
    Docs: "https://zebra.gitbook.io/zebra-docs/",
    Geckoterminal: "https://www.geckoterminal.com/scroll/zebra-scroll/pools",
  };
};

export { _collectLink };
