import { Currency, CurrencyAmount, currencyEquals, ETHER, Token } from '@mdex/zkscroll-sdk'
import React, { CSSProperties, MutableRefObject, useCallback, useMemo, useState, useEffect } from 'react'
import { FixedSizeList } from 'react-window'
import { useTranslation } from 'react-i18next'
import { Text, Flex } from 'rebass'
import styled from 'styled-components'
import { AlertCircle } from 'react-feather'
import { useActiveWeb3React } from '../../hooks'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { useAddUserToken, useRemoveUserAddedToken } from '../../state/user/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { useIsUserAddedToken } from '../../hooks/Tokens'
import Column from '../Column'
import { RowFixed, RowBetween } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { MouseoverTooltip } from '../Tooltip'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { shortenAddress } from '../../utils'
import Modal from '../Modal'
import BoxContent from './BoxContent'
import { ButtonPrimary } from '../../components/Button'
import { isMobile } from 'react-device-detect'
import { PaddedColumn} from './styleds'
import { CloseIcon } from '../../theme'
// import rightArrowIcon from '../../assets/images/right_arrow.png'

import Check from './Check'

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

const Tag = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  border-radius: 4px;
  padding: 0.25rem 0.3rem 0.25rem 0.3rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-end;
  margin-right: 4px;
`
const ImportBtn = styled.div`
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  background-color: #13F287;
  color: #000;
  font-size: 16px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  border-radius: 18px;
`
function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(8)}</StyledBalanceText>
}

const TagContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const TipsContent = styled.div<{color?: string}>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid ${({color}) => color ? color : '#03AD90'};
  border-radius: 18px;
`
const TextWrapper = styled.div<{color?: string}>`
  margin-left: 8px;
  color: ${({color}) => color ? color : '#03AD90'};
  font-size: 14px;
`

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />
  }

  const tags = currency.tags
  if (!tags || tags.length === 0) return <span />

  const tag = tags[0]

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join('; \n')}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  )
}

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
  beforeSelect,
  setShowDialog
}: {
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties,
  beforeSelect: React.Dispatch<any>,
  setShowDialog: React.Dispatch<boolean>
}) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const key = currencyKey(currency)
  // const selectedTokenList = useSelectedTokenList()
  // 当前代币是否在白名单
  // const isOnSelectedList = isTokenOnList(selectedTokenList, currency)
  // @ts-ignore
  const isOnSelectedList = currency?.tokenInfo?.whitelist

  const customAdded = useIsUserAddedToken(currency)
  const balance = useCurrencyBalance(account ?? undefined, currency)

  const removeToken = useRemoveUserAddedToken()

  const handleAddToken = (event: any) => {
    event.stopPropagation()
    beforeSelect(currency)
    setShowDialog(true)
  }
  const handleSelect = () => {
    if (!isOnSelectedList && !customAdded) {
      return
    }
    isSelected ? null : onSelect()
  }

  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      onClick={handleSelect}
      disabled={isSelected}
      selected={otherSelected}
    >
      <CurrencyLogo currency={currency} size={'24px'} />
      <Column>
        <Text title={currency.name} fontWeight={500}>
          {currency.symbol}
        </Text>
        <FadedSpan>
          {!isOnSelectedList && customAdded ? (
            <TYPE.main fontWeight={500} fontSize={isMobile?12:14}>
              Added by user
              <LinkStyledButton
                onClick={event => {
                  event.stopPropagation()
                  if (chainId && currency instanceof Token) removeToken(chainId, currency.address)
                }}
              >
                (Remove)
              </LinkStyledButton>
            </TYPE.main>
          ) : null}
          {!isOnSelectedList && !customAdded ? (
            <TYPE.main fontWeight={500}>
              Found by address
              {/* <LinkStyledButton
                onClick={(event) => handleAddToken(event)}
              >
                (Add)
              </LinkStyledButton> */}
            </TYPE.main>
          ) : null}
        </FadedSpan>
      </Column>
      <TokenTags currency={currency} />
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {!isOnSelectedList && !customAdded ? <ImportBtn onClick={(event) => handleAddToken(event)}>{t('import')}</ImportBtn> : (balance ? <Balance balance={balance} /> : account ? <Loader /> : null)}
      </RowFixed>
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH
}: {
  height: number
  currencies: Currency[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showETH: boolean
}) {
  const { t } = useTranslation()
  // @ts-ignore
  Currency.ETHER.tokenInfo = {whitelist: true}
  const itemData = useMemo(() => (showETH ? [Currency.ETHER, ...currencies] : currencies), [currencies, showETH])
  const [showDialog, setShowDialog] = useState(false)
  const [select, setSelect] = useState<any>('')
  const [isCheck, setIsCheck] = useState(false)
  const addToken = useAddUserToken()

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data[index]
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency)
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          setShowDialog={setShowDialog}
          beforeSelect={setSelect}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [onCurrencySelect, otherCurrency, selectedCurrency]
  )
  const handleImport = () => {
    if (select instanceof Token) {
      addToken(select)
    }
    setShowDialog(false)
  }

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  useEffect(() => {
    !showDialog && setIsCheck(false)
  }, [showDialog])

  return (
    <>
      <FixedSizeList
        height={height}
        ref={fixedListRef as any}
        width="100%"
        itemData={itemData}
        itemCount={itemData.length}
        itemSize={56}
        itemKey={itemKey}
      >
        {Row}
      </FixedSizeList>
      <Modal isOpen={showDialog} onDismiss={() => setShowDialog(false)} maxHeight={80} minHeight={40}>
        <Column style={{ width: '100%', flex: '1 1' }}>
          <PaddedColumn gap="14px" >
            <RowBetween>
              {/* <img width='26px' onClick={() => setShowDialog(false)} src={rightArrowIcon} alt="arrow" /> */}
              <Text fontWeight={800} fontSize={18}>
                {t('importToken')}
              </Text>
              <CloseIcon onClick={() => setShowDialog(false)} />
            </RowBetween>
            <BoxContent isUnknow={!(select.tokenInfo && select.tokenInfo.logoURI)}></BoxContent>
            {(!select.tokenInfo || !select.tokenInfo.logoURI) &&
            <TipsContent color='#ff5357'>
              <AlertCircle color='#ff5357' size='20px'/>
              <TextWrapper color='#ff5357'>{t('unknown')}</TextWrapper>
            </TipsContent>}
            <Flex alignItems='center'>
              <CurrencyLogo style={{marginRight: '10px'}} currency={select} size={'24px'} />
              <Column>
                <Text fontSize='20px' title={select.name} fontWeight={500}>
                  {select.symbol}
                </Text>
                <Text fontWeight={500} color='#FFFFFF80'>
                  {select.address && shortenAddress(select.address)}
                </Text>
              </Column>
            </Flex>
            <Check address={select.address} isCheck={isCheck} setIsCheck={setIsCheck}></Check>
            <ButtonPrimary disabled={!isCheck} onClick={handleImport}>{t('import')}</ButtonPrimary>
          </PaddedColumn>
        </Column>
      </Modal>
    </>
  )
}
