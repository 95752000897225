import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { TokenList, Version } from '@uniswap/token-lists'
import { API_URL } from '../../constants'

export const fetchTokenList: Readonly<{
  pending: ActionCreatorWithPayload<{ url: string; requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ url: string; tokenList: TokenList; requestId: string }>
  rejected: ActionCreatorWithPayload<{ url: string; errorMessage: string; requestId: string }>
}> = {
  pending: createAction('lists/fetchTokenList/pending'),
  fulfilled: createAction('lists/fetchTokenList/fulfilled'),
  rejected: createAction('lists/fetchTokenList/rejected')
}

export async function getRewards() {
  try {
    const response = await fetch(`${API_URL}/mingpool/swap?mdex_chainid=128`, {
      method: 'GET',
      headers: new Headers({'content-type': 'application/json'})
    })
    const json = await response.json()
    return json
  } catch (error) {
    console.log('getRewards:' + error)
  }
}

export async function getApy() {
  try {
    const response = await fetch(`${API_URL}/pool/apys`, {
      method: 'GET',
      headers: new Headers({'content-type': 'application/json'})
    })
    const json = await response.json()
    return json
  } catch (error) {
    console.log('getApy:' + error)
  }
}

export const acceptListUpdate = createAction<string>('lists/acceptListUpdate')
export const addList = createAction<string>('lists/addList')
export const removeList = createAction<string>('lists/removeList')
export const selectList = createAction<string>('lists/selectList')
export const rejectVersionUpdate = createAction<Version>('lists/rejectVersionUpdate')
